import { useQuery } from '@apollo/client';
import * as cornerstone from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Box, Button, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Select, InputGroup, Input, InputRightElement, ModalFooter, useToast, Grid, Switch } from '@chakra-ui/react';
import { IoGrid, IoPlayCircle, IoRefreshOutline  } from 'react-icons/io5';
import { MeasurementEntity, StudyEntity, StudyFileEntity } from '../../../../Models/Entities';
import ImageViewerViewport, { ImageViewerViewportRef, xhrRequest } from './ImageViewerViewport';
import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest } from '@smithy/protocol-http';
import { SignatureV4 } from '@smithy/signature-v4';
import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import { DefaultUnitMap, getUs2MeasurementName, PrecisionMap } from 'Util/measurements_template';
import initialMeasurementStructure from '../../../../Assets/data/measurementStructure.json';
import us2MeasurementMap from '../../../../Assets/data/us2MeasurementMap.json';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import publicIp from 'public-ip';

cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;

interface NewImageViewerProps {
	study: StudyEntity;
	isImageViewerFullScreen: boolean;
	toggleStressCallback?: ()=>void;
}

interface UrlObject {
	url: string;
	sopInstanceUid: string;
}

const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const SERVICE = 'medical-imaging';
const HOST = 'dicom-medical-imaging.ap-southeast-2.amazonaws.com';

const signUrlCache: Record<string, string> = {};

const signUrl = async (url: string): Promise<string> => {
	if (signUrlCache[url]) return signUrlCache[url];

	const parsedUrl = new URL(url);
	// @ts-ignore
	const queryParams = Object.fromEntries(parsedUrl.searchParams.entries());

	const signer = new SignatureV4({
		credentials: {
			accessKeyId: ACCESS_KEY_ID!,
			secretAccessKey: SECRET_ACCESS_KEY!,
		},
		service: SERVICE,
		region: REGION!,
		sha256: Sha256,
	});

	const request = new HttpRequest({
		method: 'GET',
		protocol: 'https:',
		hostname: parsedUrl.hostname,
		path: parsedUrl.pathname,
		query: queryParams,
		headers: {
			host: HOST,
			accept: 'application/dicom; transfer-syntax=1.2.840.10008.1.2.4.202',
		},
	});

	const signedRequest = await signer.sign(request);
	const signedUrl = `${signedRequest.protocol}//${signedRequest.hostname}${signedRequest.path}?${new URLSearchParams(signedRequest.query as Record<string, string>).toString()}`;
	signUrlCache[url] = signedUrl; 

	return signedUrl;
};

const NewImageViewer = (props: NewImageViewerProps) => {
	const { study, toggleStressCallback } = props;
	const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);
	const [loadedFileIndexes, setLoadedFileIndexes] = useState<number[]>([0]);
	const [viewportCount, setViewportCount] = useState<number>(1);
	const [visibleViewports, setVisibleViewports] = useState<UrlObject[]>([]);
	const [displayGridSelect, setDisplayGridSelect] = useState<boolean>(false);
	const [gridCurrentHover, setGridCurrentHover] = useState<number | null>(null);
	const [gridHoveredRow, setGridHoveredRow] = useState<number>(1);
	const [gridHoveredCol, setGridHoveredCol] = useState<number>(1);
	const [gridSelectedRow, setGridSelectedRow] = useState<number>(1);
	const [viewportCurrentImages, setViewportCurrentImages] = useState<object>({});
	const [viewportCachedImages, setViewportCachedImages] = useState<string[]>([]);
	const [US2Active, setUS2Active] = useState(false);
	const [sopUID, setSopUID] = useState<string | null>('');
	const [urls, setUrls] = useState<UrlObject[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [displayPlaybackToggle, setDisplayPlaybackToggle] = useState(false);
	const navigate = useNavigate();
	const abortControllersRef = useRef<AbortController[]>([]);
	
	const viewportRefs = useRef<React.RefObject<ImageViewerViewportRef>[]>([]);
	const thumbnailRefs = useRef([]);

	const thumbnailContainer =  useRef(null);

	// @ts-ignore
	const initializeViewportRefs = index => {
		if (!viewportRefs.current[index]) {
			viewportRefs.current[index] = React.createRef();
		}
	};
	

	const preloadAbortController = useRef<AbortController | null>(null);
	
	const createAbortController = (index: number): AbortController => {
		if (!abortControllersRef.current[index]) {
			abortControllersRef.current[index] = new AbortController();
		}
		return abortControllersRef.current[index];
	};

	const cancelAllRequests = () => {
		abortControllersRef.current.forEach((controller, index) => {
			controller.abort();
		});

		abortControllersRef.current = [];
	};

	const cancelPreloading = () => {
		if (preloadAbortController.current) {
			preloadAbortController.current.abort();
			preloadAbortController.current = null;
		}
	};

	const stopAllViewports = () => {
		viewportRefs.current.forEach(ref => {
			if (ref && ref.current) {
				ref.current.stopPlaybackIfActive(); 
			}
		});
	};

	const destroyAllViewports = () => {
		viewportRefs.current.forEach(ref => {
			if (ref && ref.current) {
				const element = ref.current as any;
				if (element && element.destroy) {
					element.destroy();
				}
			}
		});
	};
	
	const toast = useToast();
	const [createMeasurementModelOpen, setCreateMeasurementModalOpen] = React.useState<boolean>(false);
	const [createMeasurementValues, setCreateMeasurementValues] = React.useState<string[]>([]);
	const [createMeasurementSelections, setCreateMeasurementSelections] = React.useState<string[]>([]);
	const createMeasurementOptions = Object.keys(initialMeasurementStructure).map(key => ({
		'label' : initialMeasurementStructure[key].name,
		'key' : key,
	}));

	const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
		input !== null && input.tagName === 'IFRAME';
	
	const { loading: thumbnailsLoading, data: thumbnails, error: thumbnailErrors } = useQuery(StudyFileEntity.getFetchStudyFileThumbnail(), {
		variables: {
			args: [{ path: 'studyId', comparison: 'equal', value: study.id }],
			orderBy: [{ path: 'InstanceId', descending: false }],
		},
		fetchPolicy: 'no-cache',
	});

	const studyFileEntitys = thumbnails?.studyFileEntitys;

	const cleanupVisibleViewports = () => {
		visibleViewports.forEach(viewport => {
			const elementId = `viewport_${viewport.sopInstanceUid}`;
			const element = document.getElementById(elementId);

			if (element) {
				cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.unload(viewport.url);
			}
		});
	};

	const wrappedXhrRequest = (uri: string, signal?: AbortSignal) => {
		return new Promise((resolve, reject) => {
			xhrRequest(uri, signal)
				.then(resolve)
				.catch((error) => {
					if (signal?.aborted) {
						reject(new DOMException('Request aborted', 'AbortError'));
					} else {
						reject(error);
					}
				});
		});
	};

	const startPreloading = async () => {
		cancelPreloading(); 
		preloadAbortController.current = new AbortController();
		try {
			await preloadImagesInBatches(0, preloadAbortController.current.signal);
			console.log('Preloading completed');
		} catch (error) {
			// @ts-ignore
			if (error.name === 'AbortError') {
				console.log('Preloading was aborted');
			} else {
				console.error('Error during preloading:', error);
			}
		} finally {
			preloadAbortController.current = null; 
		}
	};

	const preloadImagesInBatches = async (startIndex = 0, signal?: AbortSignal) => {
		const batchSize = 4;

		if (signal?.aborted) {
			console.log('Preloading aborted before starting');
			return;
		}

		for (let i = startIndex; i < urls.length; i += batchSize) {
			const batch = urls.slice(i, i + batchSize);

			const preloadPromises = batch.map(async (image) => {
				try {
					const signedUrl = await signUrl(image.url);

					if (signal?.aborted) {
						console.log(`Preloading aborted for URL: ${image.url}`);
						throw new DOMException('Preloading aborted', 'AbortError');
					}

					if (!cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.isLoaded(signedUrl)) {
						await cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(signedUrl, (url: string) => wrappedXhrRequest(url, signal));
					}
				} catch (error) {
					if (signal?.aborted) {
						console.warn('Aborted while preloading', image.url);
					} else {
						console.error(`Error preloading image: ${image.url}`, error);
					}
				}
			});

			await Promise.allSettled(preloadPromises);

			if (signal?.aborted) {
				return;
			}
		}
	};


	const selectImage = async (index: number, selectedStudyFile?: StudyFileEntity) => {

		if (selectedStudyFile && viewportCurrentImages[Object.keys(viewportCurrentImages)[0]] === selectedStudyFile.sopInstanceUid) {
			return;
		}
		setViewportCurrentImages({})
		stopAllViewports();  

		setUS2Active(false);
		const signedUrls = await Promise.all(
			urls.slice(index, index + viewportCount).map(async item => ({
				...item,
				url: await signUrl(item.url),
			}))
		);
		cleanupVisibleViewports();
		setVisibleViewports(signedUrls);

		if (signedUrls[0]) {
			setSopUID(signedUrls[0].sopInstanceUid);
		}
		setSelectedFileIndex(index);
		// @ts-ignore
		const offsetValue = thumbnailRefs.current[index].current.offsetTop;
		// @ts-ignore
		thumbnailContainer.current.scrollTop = offsetValue - 20;
	};

	const startUp = async () => {
		if (urls.length > 0) {
			const firstImage = await signUrl(urls[0].url);
			setVisibleViewports([{ ...urls[0], url: firstImage }]);
			setSopUID(urls[0].sopInstanceUid);
			setLoading(false);

			await startPreloading(); 
		}
	};

	const iframeLoadEvent = async () => {
		const iframe = document.getElementById('US2ViewerIframe');
		Axios.get(`${SERVER_URL}/viewport/us2auth`).then(res => {
			if (iframe && isIFrame(iframe) && iframe.contentWindow) {
				iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(res.data)),'https://app.us2.ai');
			}
		})
	}

	const iframeMessageHandler = async (event: MessageEvent) => {
		const ipAddress = await publicIp.v4();
		let relevantEventPresent = false;
		if (event.origin === 'https://app.us2.ai') {
			console.log(event.data);
			event.data.forEach((dataItem: any) => {
				if (dataItem.action === 'create') {
					relevantEventPresent = true;
					console.log('Measurement Event: ',{
						'Code' : dataItem.new_fields.code_id,
						'Us2 Name' : getUs2MeasurementName(dataItem.new_fields.code_id),
						'Unit' : dataItem.new_fields.unit,
						'Value' : dataItem.new_fields.value
					});
				}
			});
			if (relevantEventPresent) {
				setCreateMeasurementSelections([]);
				setCreateMeasurementValues([]);
				const selectionsList: string[] = [];
				const valuesList: string[] = [];
				event.data.forEach((dataItem: any) => {
					if (dataItem.action === 'create') {
						const readingId = dataItem.new_fields.code_id;
						let readingUnit = dataItem.new_fields.unit;
						let readingValue = dataItem.new_fields.value;

						const readingName = getUs2MeasurementName(readingId);
						const measurementObjectKey = us2MeasurementMap[readingName];
						if (readingUnit === 'mm') {
							readingValue = readingValue / 10;
							readingUnit = 'cm';
						}
						if (measurementObjectKey) {
							selectionsList.push(us2MeasurementMap[readingName]);
							readingValue = readingValue.toFixed(PrecisionMap[initialMeasurementStructure[measurementObjectKey].key]);
						} else {
							selectionsList.push('');
							readingValue = readingValue.toFixed(2);
						}
						valuesList.push(readingValue);
					}
				});
				if (selectionsList.length && valuesList.length && selectionsList.length === valuesList.length) {
					setCreateMeasurementSelections(selectionsList);
					setCreateMeasurementValues(valuesList);
					setCreateMeasurementModalOpen(true);
				}
			}
		}
	}

	const createNewMeasurement = () => {
		createMeasurementSelections.forEach((item, index) => {
			if (initialMeasurementStructure[item] !== undefined) {
				const newMeasurement = new MeasurementEntity({
					unit: DefaultUnitMap[initialMeasurementStructure[item].key],
					name: item,
					studyId: props.study.id,
					value: parseFloat(createMeasurementValues[index])
				});
				newMeasurement.save().then(res => {
					setCreateMeasurementModalOpen(false);
					toast({
						title: 'Update Successful',
						description: 'New Measurement Created',
						position: 'bottom-right',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				});
			}
		});
	}

	const resetViewports = () => {
		viewportRefs.current.forEach(ref => {
			if (ref && ref.current) {
				ref.current.resetViewport(); 
			}
		});
	}

	useEffect(() => {
		window.addEventListener('message', iframeMessageHandler);

		return () => {
			window.removeEventListener('message', iframeMessageHandler);
		};
	}, []);

	useEffect(() => {
		return () => {
			stopAllViewports();
			cancelAllRequests(); 
			cancelPreloading();
			cleanupVisibleViewports(); 
			cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.purge(); 
		};
	}, []);
	
	useEffect(() => {
		if (urls.length > 0) {
			startUp();
		}
		return () => {
			stopAllViewports();
			cleanupVisibleViewports();
			cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.purge();
		};
	}, [urls]);

	useEffect(() => {
		const updateVisibleViewports = async () => {
			const signedUrls = await Promise.all(
				urls.slice(selectedFileIndex, selectedFileIndex + viewportCount).map(async item => ({
					...item,
					url: await signUrl(item.url),
				}))
			);
			setVisibleViewports(signedUrls);
		};
		updateVisibleViewports();
		if (loadedFileIndexes.indexOf(selectedFileIndex) === -1) {
			loadedFileIndexes.push(selectedFileIndex)
			setLoadedFileIndexes(loadedFileIndexes)
		}
	}, [viewportCount, selectedFileIndex, urls]);

	const selectGridPosition = (index: number) => {
		stopAllViewports();
		const col = Math.floor(index / 3) + 1;
		const row = (index % 3) + 1;
		setGridSelectedRow(row);
		setViewportCount(row * col);
		setDisplayGridSelect(false);
	};

	const viewportWidth = (rowCount: number) => rowCount === 3 ? '33%' : rowCount === 2 ? '50%' : '100%';

	useEffect(() => {
		if (gridCurrentHover !== null && gridCurrentHover >= 0 && gridCurrentHover < 9) {
			const row = Math.floor(gridCurrentHover / 3);
			const col = gridCurrentHover % 3;
			setGridHoveredRow(row + 1);
			setGridHoveredCol(col + 1);
		}
	}, [gridCurrentHover]);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			const key = e.keyCode;
			if (key === 40) {
				const nextIndex = selectedFileIndex + visibleViewports.length >= urls.length ? 0 : selectedFileIndex + visibleViewports.length;
				selectImage(nextIndex);
			}
			if (key === 38) {
				const nextIndex = selectedFileIndex - visibleViewports.length < 0 ? urls.length - visibleViewports.length : selectedFileIndex - visibleViewports.length;
				selectImage(nextIndex);
			}
		};
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [selectImage]);

	useEffect(() => {
		if (thumbnails?.studyFileEntitys) {
			const filteredStudyFileEntitys = thumbnails.studyFileEntitys.filter((file: StudyFileEntity) =>
				file?.thumbnail &&
				file?.seriesInstanceUid &&
				file?.sopInstanceUid &&
				file?.ahiImageSetId
			);

			let datastoreId = process.env.REACT_APP_DATASTORE_ID || '';
			
			if (study.patient.site.ahiDatastore?.datastoreId) {
				datastoreId = study.patient.site.ahiDatastore?.datastoreId;
			}
			
			const urlArray = filteredStudyFileEntitys.map((file: StudyFileEntity) => ({
				url: `https://dicom-medical-imaging.ap-southeast-2.amazonaws.com/datastore/${datastoreId}/studies/${study.studyInstanceUID}/series/${file.seriesInstanceUid}/instances/${file.sopInstanceUid}?imageSetId=${file.ahiImageSetId}`,
				sopInstanceUid: file.sopInstanceUid,
			}));

			setUrls(urlArray);
		}
	}, [thumbnails, study.id]);

	const width = useMemo(() => viewportWidth(gridSelectedRow), [gridSelectedRow]);

	const getThumbnailBorder = (thumbnail: StudyFileEntity) => {
		let border = '1px solid transparent';
		if (viewportCachedImages.indexOf(thumbnail.sopInstanceUid) > -1) {
			border = '1px solid #aaa';
		}
		Object.keys(viewportCurrentImages).forEach((key: string) => {
			if (viewportCurrentImages[key] === thumbnail.sopInstanceUid) {
				border = '1px solid red';
			}
		});
		return border;
	};

	const toggleUS2View = () => {
		if (study.studyInstanceUID && visibleViewports[0].sopInstanceUid) {
			setUS2Active(!US2Active);
		}
	}

	const viewportElements = visibleViewports.map((studyFile, index) => {
		initializeViewportRefs(index);
		const controller = createAbortController(index);
		
		return (
			<Box key={studyFile.sopInstanceUid} width={width} cursor="pointer">
				<ImageViewerViewport
					displayPlaybackToggle={displayPlaybackToggle}
					ref={viewportRefs.current[index]}
					url={studyFile.url}
					studyFileId={studyFile.sopInstanceUid}
					index={index}
					abortController={controller} 
					currentFileCallback={(fileId, viewport) => {
						viewportCurrentImages[viewport] = fileId;
						setViewportCurrentImages({ ...viewportCurrentImages });
					}}
					deselectFileCallback={(viewport) => {
						delete viewportCurrentImages[viewport];
						setViewportCurrentImages({ ...viewportCurrentImages });
					}}
					cachedFileCallback={(fileId) => {
						if (!viewportCachedImages.includes(fileId)) {
							setViewportCachedImages([...viewportCachedImages, fileId]);
						}
					}}
				/>
			</Box>
		);
	});

	useEffect(() => {
		if(studyFileEntitys) {
			thumbnailRefs.current = studyFileEntitys.map((file: StudyFileEntity, i: number) => thumbnailRefs.current[i] || React.createRef());
		}
	  }, [studyFileEntitys]);
	
	if (thumbnailsLoading) {
		return <></>
	}
	
	if (loading) {
		return (
			<Flex justify="center" align="center" height="100%">
				<Spinner size="xl" />
			</Flex>
		);
	}

	// @ts-ignore
	return (
		<Flex height="100%">
			<Grid w="230px" height="100%" maxHeight="100%" overflowY="scroll" gridTemplateRows="repeat(auto-fill, 60px);" gridTemplateColumns="75px 1fr;" gap="5px" ref={thumbnailContainer} scrollBehavior='smooth'>
				{studyFileEntitys
					.filter((thumbnail: StudyFileEntity) => thumbnail.thumbnail !== '')
					.map((thumbnail: StudyFileEntity, index: number) => {
						initializeViewportRefs(index);
						return (
							<Box ref={thumbnailRefs.current[index]} key={thumbnail.sopInstanceUid} style={{ width: '80px', height: '60px' }} onClick={() => selectImage(index, thumbnail)} cursor="pointer"  border={getThumbnailBorder(thumbnail)}>
								<img src={`data:image/png;base64,${thumbnail.thumbnail}`} style={{ width: '80px', height: '60px', padding: '5px' }} />
							</Box>
						)
					})}
			</Grid>
			<Flex direction="column" width="100%" position="relative">
				{study.patient.site.us2ViewerEnabled && study.studyInstanceUID && sopUID ? (
					<Flex className={US2Active ? '' : 'us2ViewerDisabled'} direction="column" position="relative" width="100%">
						<Flex mb="5px">
							<Button colorScheme="blue" onClick={toggleUS2View}>Cancel</Button>
						</Flex>
						<Flex width="100%" height="100%" wrap="wrap" paddingBottom="50px" paddingRight="40px">
							<iframe
								id='US2ViewerIframe'
								src={`https://app.us2.ai/#/ap-southeast-2/search;tags=StudyInstanceUID:${study.studyInstanceUID}/views;sop=${sopUID}`}
								title={`US2 ${sopUID}`}
								style={{ width: US2Active ? '100%' : '20px', height: '800px' }}
								key={sopUID}
								onLoad={iframeLoadEvent}
							/>
						</Flex>
					</Flex>
				) : ''}
				{US2Active ? <></> : (
					<>
						<Flex mb="5px"  justifyContent='space-between'>
							<Box>
								<Button colorScheme="link" onClick={() => { 
									stopAllViewports();
									navigate('/') 
									}}>
									<ChevronLeftIcon fontSize='20px'></ChevronLeftIcon> Close Study
								</Button>
							</Box>
							<Flex>
								<Button colorScheme="blue" onClick={() => { resetViewports() }} ml='10px'>
									<IoRefreshOutline  fontSize="20px" color="#fff" />
								</Button>
								<Button colorScheme="blue" onClick={() => setDisplayGridSelect(!displayGridSelect)}  ml='10px'>
									<IoGrid fontSize="20px" color="#fff" />
									{displayGridSelect && (
										<Grid
											position="absolute"
											top="45px"
											left="0px"
											background="#373151"
											borderRadius="2px"
											gridGap="3px"
											gridTemplateColumns="repeat(3, 27px)"
											gridTemplateRows="repeat(3, 27px)"
											zIndex="100"
											padding="5px"
										>
											{[0, 1, 2, 3, 4, 5, 6, 7, 8].map(item => (
												<Box
													key={item}
													borderRadius="1px"
													width="25px"
													height="25px"
													margin="1px"
													backgroundColor={Math.floor(item / 3) < gridHoveredRow && item % 3 < gridHoveredCol ? '#4806cf' : '#180b417d'}
													onMouseEnter={() => setGridCurrentHover(item)}
													cursor="pointer"
													onClick={() => selectGridPosition(item)}
												/>
											))}
										</Grid>
									)}
								</Button>
								<Button colorScheme="blue" onClick={() => { setDisplayPlaybackToggle(!displayPlaybackToggle) }} ml='10px'>
									<IoPlayCircle fontSize="20px" color="#fff" />
								</Button>
								{study.patient.site.us2ViewerEnabled && (
									<Button colorScheme="blue" onClick={toggleUS2View} ml="10px">
									Add Measurement
									</Button>
								)}
								{
									['STRESS_ECHO', 'DOBUTAMINE_STRESS_ECHO'].includes(study.studyType) ?
										<Flex color='#fff' justifyContent='flex-end' alignItems='center' width='120px'>
											<Box mr='10px' mb='4px' fontSize='16px' letterSpacing='.5px'>
							Standard
											</Box> 
											<Switch colorScheme='blue' isChecked={false} onChange={() => {
												stopAllViewports();
												toggleStressCallback ? toggleStressCallback() : '';
											}} />
										</Flex>
										: '' }
							</Flex>
						</Flex>
						<Flex width="100%" height="100%" wrap="wrap" paddingBottom="50px" paddingRight="40px">
							{viewportElements}
						</Flex>
					</>
				)}
			</Flex>
			<Modal isOpen={createMeasurementModelOpen} onClose={() => setCreateMeasurementModalOpen(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add New Measurement</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{createMeasurementSelections.map((selection, index) => (
							<React.Fragment key={index}>
								<Box>Measurement Type:
									<Select
										size="sm"
										variant="outline"
										value={selection}
										onChange={e => {
											const selections = [...createMeasurementSelections];
											selections[index] = e.target.value;
											setCreateMeasurementSelections(selections);
										}}
									>
										<option value="" key=""></option>
										{createMeasurementOptions.map(option => (
											<option value={option.key} key={option.key}>{option.label}</option>
										))}
									</Select>
								</Box>
								Value:
								<InputGroup>
									<Input
										m="0"
										type="text"
										size="sm"
										variant="outline"
										defaultValue={createMeasurementValues[index]}
										onBlur={e => {
											const values = [...createMeasurementValues];
											values[index] = e.target.value;
											setCreateMeasurementValues(values);
										}}
									/>
									<InputRightElement
										height="100%"
										background="#ebebeb"
										color="#353535"
										fontWeight="600"
										fontSize="14px"
										width="auto"
										minWidth="30px"
										padding="0 4px 3px 4px"
									>
										{selection ? DefaultUnitMap[initialMeasurementStructure[selection].key] : 'mm'}
									</InputRightElement>
								</InputGroup>
								{index !== createMeasurementSelections.length - 1 && <Box width="100%" height="1px" bgColor="#ccc" m="16px 0" />}
							</React.Fragment>
						))}
					</ModalBody>
					<ModalFooter>
						<Button variant="ghost" onClick={() => setCreateMeasurementModalOpen(false)}>Cancel</Button>
						<Button colorScheme="blue" mr={3} onClick={createNewMeasurement}>
							Create Measurement{createMeasurementSelections.length > 1 ? 's' : ''}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

export default NewImageViewer;
